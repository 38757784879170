/* a l'affiche / Prochainement / Coup de coeur / Recommande */
$(function () {
    $('.home-zoom.tab-content div ul li').mouseover(function () {
        $('.home-zoom.tab-content div ul li .title').hide();
        $(this).find('.title').show();
        $(this).find('.btn-resa').addClass('hover');
    }).mouseout(function () {
        $('.home-zoom.tab-content div ul li .btn-resa').removeClass('hover');
        $(this).find('.title').hide();
    });
});

/* Infoconcert TV - switch videos */
$(function () {
    $('.video-content-list a.video-src').click(function () {

        var parent = $(this).parent().parent(); // <li>
        $('.video-list-infos').removeClass('active');
        parent.toggleClass('active');

        var id = $(this).attr('href');
        var id = id.replace('#', '');

        $.ajax({
            url: '/assets/js/ajax/get_ictv.php?video_id=' + id,
            cache: false
        }).done(function (data) {
            $('.video-content-main').html(data);
            if (typeof $('div.video-content-main iframe').attr('src') !== 'undefined') {
                $videoSrc = $('div.video-content-main iframe').attr('src');
                $('div.video-content-main iframe')[0].src += "?autoplay=1";
            }
        });

        return false;
    });
});

/*------------------------------------*\
 SLICK SECTION SELECTION CAROUSEL
\*------------------------------------*/

(function ($) {
    $('.slide-zoom').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: '<span class="next"><i class="fas fa-angle-right"></i></span>',
        prevArrow: '<span class="previous"><i class="fas fa-angle-left"></i></span>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
        ]
    });

})(jQuery);



/*------------------------------------*\
 SLICK COMING SOON CAROUSEL
\*------------------------------------*/

(function ($) {
    $('.coming-soon-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: '<span class="next"><i class="fas fa-angle-right"></i></span>',
        prevArrow: '<span class="previous"><i class="fas fa-angle-left"></i></span>'
    });
})(jQuery);

